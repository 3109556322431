<template>
  <header class="app-header navbar">
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" @click="mobileSidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <b-link class="navbar-brand" to="#"></b-link>
    <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" @click="sidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>

    <ul class="nav" v-if="hasPermission('ToolsRestart')">
      <li class="nav-item"><div><span @click="restartTools" class="nav-link"><i class="icon-refresh"></i> Restart tools</span></div></li>
    </ul>


    <b-navbar-nav class="ml-auto">
      <Requests/>

      <HeaderDropdown/>

    </b-navbar-nav>
    <button class="navbar-toggler aside-menu-toggler d-md-down-none" type="button">
    </button>
  </header>
</template>
<script>
  import HeaderDropdown from './HeaderDropdown.vue'
  import Requests from './Requests/Requests'

  export default {
    name: 'c-header',
    components: {
      HeaderDropdown,
      Requests
    },
    methods: {
      restartTools() {
        this.emit("restartTools")
      },
      sidebarToggle (e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-hidden')
      },
      sidebarMinimize (e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-minimized')
      },
      mobileSidebarToggle (e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-mobile-show')
      },
      asideToggle (e) {
        e.preventDefault()
        document.body.classList.toggle('aside-menu-hidden')
      }
    }
  }
</script>
