<template>
  <div class="app flex-row align-items-center login-background">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4 login-card">
              <b-card-body>
                <img src="/img/logo-symbol.png">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors">{{ error }}</li>
                </ul>
                </p>
                <form @submit.prevent="login">
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <input type="text" v-model="user" class="form-control" placeholder="Username">
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <input type="password" v-model="pass" class="form-control" placeholder="Password">
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button variant="primary" type="submit" class="px-4">Login</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import Auth from '../../Auth'
  import {mapActions} from 'vuex'
  export default {
    name: 'Login',
    data () {
      return {
        user: null,
        pass: null,
        errors: []
      }
    },
    methods: {
      ...mapActions(['resetPermission']),
      login() {
        this.errors = []
        let invalid = false

        if (!this.user) {
          this.errors.push('Username is required.')
          invalid = true
        }
        if (!this.pass) {
          this.errors.push('Password is required.')
          invalid = true
        }
        if (invalid) {
          return
        }

        Auth.login(this.user, this.pass, (loggedIn) => {
          if (!loggedIn) {
            this.errors.push('Username or Password is incorrect.')
            return
          }
          this.resetPermission(null)

          // Reconnect socket if needed
          if (this.$socket) {
            this.$disconnect()
          }
          this.$connect()
          this.$router.push(this.$route.query.redirect || '/')
        })
      }

    }
  }
</script>
