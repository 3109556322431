
function onFirstDataRendered(params) {
  params.api.sizeColumnsToFit()
}
const DefaultServerGridOptions = {
  defaultColDef: {
    resizable: true,
    enablePivot: false
  },
  sideBar: {
    toolPanels: ['columns', 'filters'],
    defaultToolPanel: false
  },
  onGridSizeChanged: onFirstDataRendered,
  onFirstDataRendered: onFirstDataRendered
};


const DefaultGridOptions = {
  defaultColDef: {
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  sideBar: {
    toolPanels: ['columns', 'filters'],
    defaultToolPanel: false
  },
  onGridSizeChanged: onFirstDataRendered,
  onFirstDataRendered: onFirstDataRendered
};


export {DefaultGridOptions, DefaultServerGridOptions}
