<template>
    <router-link tag="li" class="nav-item nav-dropdown" :to="url" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i :class="icon"></i> {{name}}</div>
      <ul class="nav-dropdown-items">
        <slot></slot>
      </ul>
    </router-link>
</template>
<script>
  export default {
    props: {
      name: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      permission: {
        type: String,
        default: ''
      }
    },
    methods: {
      handleClick (e) {
        e.preventDefault()
        e.target.parentElement.classList.toggle('open')
      },
      resolve (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : undefined
        }, obj || self)
      }

    }
  }
</script>
