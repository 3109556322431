<template>
  <div>
  <v-autocomplete :items="items" v-model="item" ref="search" :get-label="getLabel" :component-item='template'
                  @update-items="updateItems" :auto-select-one-item="false" @item-selected="selectedItem" placeholder="Search...">
  </v-autocomplete>
    <i class="fa fa-search search-box"></i>
  </div>
</template>

<script>
  import ItemTemplate from './AutocompleteItem.vue'
  import Vue from 'vue'
  import {mapGetters, mapActions} from 'vuex'

  export default {
    events: new Vue(),
    data () {
      return {
        item: null,
        template: ItemTemplate
      }
    },
    computed: mapGetters({
      items: 'autocompleteResults'
    }),
    methods: {
      ...mapActions(['resetItems']),
      getLabel (item) {
        if (item) {
          return item.name
        }
      },
      updateItems (text) {
        this.emit('autocomplete', text)
      },
      selectedItem (item) {
        this.items = []
        if (this.$router.history.current.params.username) {
          this.$router.push(this.$router.history.current.path.replace(this.$router.history.current.params.username, item.name))
        } else {
          this.$router.push('/user/' + item.name + '/base')
        }
      }
    },
    mounted () {
      this.$on('focus', function () {
        this.$refs.search.focus()
      })
    }
  }
</script>
