// initial state
const state = {
  all: [],
  count: 0,
  toolsLog: 'Loading...'
}

// getters
const getters = {
  Auditlog: state => state.all,
  LogCount: state => state.count,
  ToolsLog: state => state.toolsLog,
}

// actions
const actions = {
  getAuditlog ({ commit }, trades) {
    commit('setAuditlog', trades.data)
  },
  getToolslog ({ commit }, trades) {
    commit('setToolslog', trades.data)
  },
  ClearLog ({ commit }, trades) {
    commit('clearToolslog')
  },
  clearAuditlog ({ commit }) {
    commit('clearAuditlog')
  }
}

// mutations
const mutations = {
  setAuditlog (state, log) {
    state.all = log.entries
    if (log.count > 0) {
      state.count = log.count
    }
  },
  clearAuditlog (state) {
    state.all = []
  },
  clearToolslog (state) {
    state.toolsLog = 'Loading...'
  },
  setToolslog (state, log) {
    state.toolsLog = log
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
