<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-envelope"></i></span>
    </div>
    <input class="form-control" v-on:keyup="validEmail" v-model="email" :disabled="userLock || readonly" :class="error ? 'is-invalid' : ''" :readonly="allowed" type="email"
           id="username" title="">
    <span class="input-group-append"><button class="btn btn-primary" :disabled="readonly || userLock || error != null || allowed" @click="changeEmail" type="button">Change</button></span>
    <div class="invalid-feedback">{{error}}</div>

  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'username',
    props: ['email', 'username', 'allowed', 'readonly'],
    data () {
      return {
        error: null
      }
    },
    computed: {
      ...mapGetters([
        'userLock'
      ])
    },
    methods: {
      validEmail () {
        if (!this.validateEmail(this.email)) {
          this.error = 'Please type a valid email.'
        } else {
          this.error = null
        }
      },
      validateEmail (email) {
        let re = /\S+@\S+\.\S+/
        return re.test(String(email).toLowerCase())
      },
      changeEmail () {
        this.setLock(true)
        this.emit('changeEmail', {
          username: this.username,
          email: this.email
        })
      },
      ...mapActions(['setLock'])
    }
  }
</script>
