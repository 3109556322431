<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header"><i class="icon-lock"></i>Permission</div>
        <div class="card-body">

          <b-row class="form-group">

            <b-col lg="2" md="3" sm="12">
              <h5 class="text-gray-700">Roles and Users              </h5>
              <br>

              <Autocomplete v-model="selectedUser"/>

              <br>


              <ul class="w-100 nav flex-column">
                <li class="nav-item"  v-for="(role, index) in roles" :key="role.name" >
                  <a class="nav-link h6"
                     @click="changeRole(index)"
                     @mouseover="hover = index"
                     @mouseleave="hover = -1"
                     :style="[hover === index || selected === index ? {'background-color': hexToRGB(role.colour, 0.1)} : {'color': role.colour }]">{{role.name}}</a>
                </li>
              </ul>
              <ul class="w-100 nav flex-column">
                <li class="nav-item user-permissions"  v-for="(perm, userid) in userPermissions" :key="userid" >
                  <a class="nav-link h6" :class="{ active: selected.id === perm.user.id }" @click="changeUser(perm.user)"><i class="icon-user"></i> {{perm.user.name}} </a>


                </li>
              </ul>
            </b-col>
            <b-col md="9" lg="10">
              <h5 class="text-gray-700">Permissions <span v-if="user_mode" class="h6 text-muted">This overwrites the permission of <span :style="{'color': roles[selected.security_id].colour}">{{roles[selected.security_id].name}}</span></span></h5>

              <template v-for="(item, index) in Object.keys(permissions)">
            <b-row>
              <template v-if="index % 4 === 0" v-for="n in 4">
                <b-col sm="12" md="6" lg="3">
                  <h6>{{permissions[Object.keys(permissions)[index + n - 1]].Name}}</h6>
                  <ul>
                    <template v-for="(perm, lol) in permissions[Object.keys(permissions)[index + n - 1]].Permissions">
                      <li class="list-unstyled" :key="perm.Permission.value">
                        <label :class="important(perm)" class="switch switch-text">
                          <template v-if="!user_mode">
                            <input :value="perm.Permission"  v-model="checked" class="switch-input" type="checkbox">
                            <span class="switch-label" data-off="Off" data-on="On"></span>
                            <span class="switch-handle"></span>
                          </template>
                          <tristate v-else :perm="perm.Permission" v-model="userChecked"></tristate>
                        </label>
                        <span b-tooltip.hover :title="perm.Description" class="ml-2"><b>{{perm.Name}}</b></span>

                      </li>
                    </template>
                  </ul>
                </b-col>
              </template>
            </b-row>
          </template>

            </b-col>

          </b-row>
          <small>
            <mark><i><span class="text-warning">Orange</span> - This is a dangerous permission to grant.</i></mark>
          </small>
          <button @click="deleteUser(selected)" v-if="user_mode" class="btn btn-danger float-right"
                  type="reset"><i class="fa fa-trash"></i> Delete {{selected.name}}
          </button>
        </div>
        <div class="card-footer">
          <button @click="apply" :checked="!hasPermission(PermissionChange)" class="btn btn-sm btn-primary"
                  type="submit"><i class="fa fa-dot-circle-o"></i> Apply
          </button>
          <button @click="loadPermission" :checked="!hasPermission(PermissionChange)" class="btn btn-sm btn-danger"
                  type="reset"><i class="fa fa-ban"></i> Reset
          </button>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
  import Permissions from '../../_permissions'
  import Roles from '../../_roles'
  import Autocomplete from './Permissions/UsernameSearch'
  import {mapGetters, mapActions} from 'vuex'
  import VueNotifications from 'vue-notifications'
  import bigInt from 'big-integer'
  import Tristate from './Permissions/Tristate'

  export default {
    name: 'permission',
    data () {
      return {
        permissions: Permissions,
        roles: Roles,
        hover: -1,
        selectedUser: null,
        selected: 2,
        userChecked: {},
        user_mode: false,
        zero: bigInt(0),
        checked: []
      }
    },
    computed: mapGetters({
      allPermissions: 'allPermissions',
      userPermissions: 'userPermissions',
      authUser: 'authUser'
    }),
    created () {
      this.emit('getPermissions')
    },
    methods: {
      ...mapActions(['deleteUserPermission', 'addUserPermission']),
      offset (role) {
        return role
      },
      important (perm) {
        if ('Important' in perm) {
          return 'switch-warning-outline-alt'
        }
        return 'switch-primary-outline-alt'
      },
      permission (role) {
        if (role in this.allPermissions) {
          return this.allPermissions[role]
        }
        return 0
      },
      hexToRGB (hex, alpha) {
        let r = parseInt(hex.slice(1, 3), 16)
        let g = parseInt(hex.slice(3, 5), 16)
        let b = parseInt(hex.slice(5, 7), 16)

        if (alpha) {
          return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
        } else {
          return 'rgb(' + r + ', ' + g + ', ' + b + ')'
        }
      },
      apply () {
        if (!this.user_mode) {
          let perm = bigInt(0)
          for (let i in this.checked) {
            perm = perm.add(this.checked[i])
          }
          this.allPermissions[this.selected] = this.checked
          this.ChangeSuccessful()
          this.emit('changePermission', {'security': this.selected, 'permission': perm.toString()})
        } else {
          let denyPerm = bigInt(0)
          let allowPerm = bigInt(0)
          for (let p in this.userChecked) {
            if (this.userChecked[p] === true) {
              allowPerm = allowPerm.add(p)
            } else if (this.userChecked[p] === false) {
              denyPerm = denyPerm.add(p)
            }
          }
          this.userPermissions[this.selected.id].perm = this.userChecked
          this.ChangeSuccessful()
          this.emit('changeUserPermission', {'user': this.selected.id, 'allow': allowPerm.toString(), 'deny': denyPerm.toString()})
        }
      },
      deleteUser (user) {
        this.emit('deleteUserPermission', this.selected.id)
        this.user_mode = false
        this.selected = 11
        this.loadPermission()
        this.deleteUserPermission(user)
        this.ChangeSuccessful()
      },
      changeRole (index) {
        this.user_mode = false
        this.selected = index
        this.loadPermission()
      },
      changeUser (user) {
        this.user_mode = true
        this.selected = user
        this.loadPermission()
      },
      loadPermission () {
        if (this.user_mode) {
          this.userChecked = Object.assign({}, this.userPermissions[this.selected.id].perm)
        } else {
          this.checked = this.allPermissions[this.selected]
        }
      }
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Permissions changed',
        message: 'Permissions was successfully changed.'
      }
    },
    components: {
      Autocomplete,
      Tristate
    },
    watch: {
      allPermissions: function (val) {
        this.loadPermission()
      },
      selectedUser (item) {
        this.addUserPermission(item)
      }
    }
  }
</script>
