<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="10">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-globe"></i> <strong>Global Vars</strong>
              <span v-if="servers[activeServer].readonly" class="badge badge-danger">Read-only</span>
              <div class="card-actions">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <template v-for="(item, index) in servers" v-if="!item.readonly">
                    <button type="button" @click="switchServer(index)"
                            :class="['btn-' + item.colour, activeServer === index ? 'active' : '']"
                            class="btn btn-small">{{capitalizeFirstLetter(index)}}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <div>

            <VariableRecords :readonly="servers[activeServer].readonly" :records="allVariables.data" :server="activeServer"/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Servers from '../../_servers'
  import VariableRecords from './VariableRecords'
  import { mapGetters } from 'vuex'

  export default {
    name: 'GlobalVars',
    components: {
      VariableRecords
    },
    computed: mapGetters({
      allVariables: 'globalVars'
    }),
    data () {
      return {
        id: 0,
        activeServer: 'gold',
        servers: Servers,
        cache: {}
      }
    },
    created () {
      this.emit('getGlobalVars', this.activeServer)
    },
    methods: {
      switchServer (index) {
        this.activeServer = index
        if (index in this.cache) {
          this.loadData(this.cache[index])
        } else {
          this.emit('getGlobalVars', index)
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getVariables', this.activeServer)
      next()
    }
  }
</script>
