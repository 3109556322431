<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 700px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :suppressClickEdit="true"
                 :enableFilter="true"
                 :defaultColDef="defaultColDef"
                 :getContextMenuItems="getContextMenuItems"
                 :groupHeaders="true"
                 :suppressRowClickSelection="true"
                 :toolPanelSuppressGroups="true"
                 :toolPanelSuppressValues="true">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import VueNotifications from 'vue-notifications'
  import { mapGetters } from 'vuex'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records', 'server', 'readonly'],
    data () {
      return {
        defaultColDef: {
          flex: 1,
        },
        gridOptions: null,
        detailCellRenderer: null,
        showToolPanel: false,
        suppressMovable: true
      }
    },
    computed: {
      ...mapGetters(['authUser'])
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this
        return [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: 'Edit',
            icon: '<i class="fa fa-pencil"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.VariablesEdit),
            action: function () {
              self.gridOptions.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: 'value'
              })
            }
          },
          {
            name: 'Edit Days',
            icon: '<i class="fa fa-calendar"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.VariablesEditDays),
            action: function () {
              self.gridOptions.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: 'days'
              })
            }
          },
          {
            name: 'Delete',
            icon: '<i class="fa fa-trash"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.VariablesDelete),
            action: function () {
              self.gridOptions.api.applyTransaction({remove: [params.node.data]})
              self.emit('deleteVariable', {id: params.node.data.id, server: self.server})
              self.ChangeSuccessful()
            }
          },
          'separator',
          'export'
        ]
      },
      onCellValueChanged (params) {
        if (params.oldValue !== params.newValue) {
            this.emit('changeVariable', {id: params.data.id, value: params.newValue, server: this.server})
            //this.ChangeSuccessful()
        }
      },
      onCellDAYSValueChanged (params) {
        if (params.oldValue !== params.newValue) {
          this.emit('changeVariableDays', {id: params.data.id, value: params.newValue, server: this.server})
          this.ChangeSuccessful()
        }
      },
      createColumnDefs () {
        return [
          {
            headerName: 'ID',
            field: 'id',
            hide: false,
            filter: 'agNumberColumnFilter'
          },
          {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            sort: 'asc'
          },
          {
            headerName: 'Value',
            field: 'value',
            filter: 'agTextColumnFilter',
            cellEditor: 'agLargeTextCellEditor',
            editable: true,
            onCellValueChanged: this.onCellValueChanged
          },
          {
            headerName: 'Date',
            field: 'date'
          },
          {
            headerName: 'Days',
            field: 'days',
            editable: true,
            filter: 'agNumberColumnFilter',
            onCellValueChanged: this.onCellDAYSValueChanged
          },
          {
            headerName: 'Python',
            field: 'python'
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      },
      PythonEditError: {
        type: VueNotifications.types.error,
        title: 'Edit failed',
        message: 'You cannot edit Python variables.'
      }
    }
  }
</script>

<style>
</style>
