// initial state
const state = {
  all: null
}

// getters
const getters = {
  Chatlogs: state => state.all

}

// actions
const actions = {
  getChatlog ({ commit }, trades) {
    commit('setChatlog', trades.data)
  }
}

// mutations
const mutations = {
  setChatlog (state, products) {
    state.all = products
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
