<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" md="12">
          <b-card
            header-tag="header"
            footer-tag="footer">
            <div slot="header">
              <i class="icon-list"></i> <strong>Watchlist</strong>
              <span class="badge badge-primary">{{ this.$route.params.username }}</span>
            </div>
            <div>
              <b-form>

                <b-form-group id="input-group-2" label="Username:" label-for="input-2">
                  <b-form-textarea
                    id="input-2"
                    placeholder="Separated with lines"
                    v-model="userr"
                    required
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group id="input-group-3" label="Reason:" label-for="input-3">
                  <b-form-select
                    id="input-3"
                    v-model="reason"
                    class="form-control"
                    :options="options"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-button type="submit" variant="primary" @click="AddWatchlist"
                          :disabled="reason ===  null  || !hasPermission(WatchlistAdd)">Add
                </b-button>
              </b-form>
              <br>
              <b-row>
                <b-col cols="6" md="6">
                  <h5>
                    Watchlist
                  </h5>
                </b-col>
              </b-row>
              <b-form-group class="d-flex justify-content-end mt-1">
                <b-form-radio-group
                  @change="activeExternalFilter"
                          :options="serverOptions"
                   name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <ag-grid-vue v-if="watchlist" class="ag-theme-balham-dark" style="width: 100%; height: 70vh"
                           :gridOptions='gridOptions'
                           :getContextMenuItems="getContextMenuItems"
                           :isExternalFilterPresent="isExternalFilterPresent"
                           :doesExternalFilterPass="doesExternalFilterPass"
                           :rowData="watchlist.watchlist"></ag-grid-vue>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import {DefaultGridOptions} from "@/default_table_settings";
import {AgGridVue} from 'ag-grid-vue'
import moment from "moment";

let filterType = 'None';

export default {
  name: 'watchlist',
  components: {
    'ag-grid-vue': AgGridVue
  },
  computed: mapGetters({
    watchlist: 'Watchlist'
  }),
  data() {
    return {
      userr: '',
      gridOptions: null,
      reason: null,
      serverOptions: ['None', 'Gold', 'Silver', 'All'],
      options: [{text: 'Select One', value: null}, 'Bot', 'Macro', 'Speedhack', 'RMT', 'Bug abuse']
    }
  },
  methods: {
    doesExternalFilterPass(node) {
      if(filterType === 'None')
        return true;
      if(node.data) {
        if(filterType === 'Gold') {
          return node.data.Servers.gold
        }
        else if(filterType === 'Silver') {
          return node.data.Servers.silver
        }
        return node.data.Servers.silver || node.data.Servers.gold
      }
      return true;
    },
    isExternalFilterPresent() {
      return filterType !== 'None'
    },
    activeExternalFilter(change) {
      filterType = change
      this.gridOptions.api.onFilterChanged();
    },
    AddWatchlist() {
      this.emit('addWatchlist', {'username': this.userr.trim().split('\n'), 'reason': this.reason, 'note':''})
      this.userr = ''
      this.reason = null
    },
    deleteWatchlist(us) {
      this.emit('deleteWatchlist', us)
    },
    getContextMenuItems: function getContextMenuItems(params) {
      let self = this
      return [
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: 'Delete',
          icon: '<i class="fa fa-trash"/>',
          disabled: self.readonly || !params.column,
          action: function () {
            const sel = self.gridOptions.api.getSelectedRows();
            self.gridOptions.api.applyTransaction({remove: sel});
            for (const row of sel) {
              self.emit('deleteWatchlist', row.Username)
            }
          }
        },
        'separator',
        'export'
      ]
    },
    createColumnDefs: function () {
      let self = this
      return [
        {
          field: 'Username',
          cellRenderer: (params) => {
            const route = "/user/" + params.data.Username + "/base";

            const link = document.createElement("a");
            link.href = self.$router.resolve(route).href;
            link.innerText = params.value;
            link.addEventListener("click", e => {
              e.preventDefault();
              self.$router.push(route);
            });
            return link;
          }
        },
        {
          field: 'Staff'
        },
        {
          field: 'Reason'
        },
        {
          field: 'Note',
          onCellValueChanged: this.onReasonChange,
          editable: true
        },
        {
          field: 'Date',
          valueGetter: (data) => {
            if(data.data.Date) {
              return moment(data.data.Date).format('MM/DD/YYYY HH:mm')
            }
            return ''
          }
        },
        {
          field: 'Online',
          sortable: true,
          cellRenderer: (params) => {
            return Object.entries(params.data.Servers)
              .filter(([key, value]) => value)
              .map(([key, value]) => '<b class="text-' + key + '">' + key[0].toUpperCase() + key.substring(1).toLowerCase() + '</b>')
              .join(',')
          },
          valueGetter: (val) => {

            return Object.entries(val.data.Servers)
              .filter(([key, value]) => value)
              .map(([key, value]) => key)
              .join(',')
          },
          sort: "desc"
        }
      ]
    },
    onReasonChange(params) {
      if (params.oldValue !== params.newValue) {
        this.emit('addWatchlist', {'username': [params.data.Username], 'note': params.newValue, 'reason': params.data.Reason})

        //params.api.showLoadingOverlay();
        //this.emit('updateLogReason', {'id': Number(params.data.id), 'reason': params.newValue})
      }
    }
  },
  beforeMount() {
    let self = this
    this.gridOptions = Object.assign({}, DefaultGridOptions, {
      rowSelection: 'multiple',
      columnDefs: this.createColumnDefs()
    })
  },
  mounted() {
    this.ts = Date.now()
    this.emit('getWatchlist', {})
  },
  beforeDestroy() {
    clearInterval(this.polling)
  }
}
</script>

<style scoped>

</style>
