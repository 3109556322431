<template>
  <div class="wrapper">
    <div class="animated fadeIn" v-if="snap">
      <div>
        <b-nav pills>
          <b-nav-item v-for="(_, key) in snap" v-if="key !== 'ID'" :active="activeTab === key" @click="activeTab = key">{{ key}}</b-nav-item>
        </b-nav>
        <br>
          <b-card>
          <b-card-header><h5>{{activeTab}}</h5></b-card-header>
            <b-container fluid="sm" v-if="Object.entries(snap[activeTab]).length > 0"><br>

              <b-row v-if="!Array.isArray(snap[activeTab])">
                <template v-for="(value, key) in snap[activeTab]">
                  <dt class="col-sm-3">{{key}}:</dt>
                  <dd class="col-sm-3">{{value}}</dd>
                </template>
              </b-row>
              <ag-grid-vue v-else style='width: 100%; height: 700px' class='ag-theme-alpine-dark'
                            :columnDefs="getColumns(snap[activeTab][0])"
                            :gridOptions='gridOptions'
                            :rowData="snap[activeTab]">
              </ag-grid-vue>
            </b-container>
          </b-card>
      </div>
    </div>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import Servers from '../../../_servers'
  import VueNotifications from 'vue-notifications'
  import {DefaultGridOptions} from "@/default_table_settings";
  import {AgGridVue} from 'ag-grid-vue'
  import 'ag-grid-enterprise'
  export default {
    name: 'Snapshot',
    computed: mapGetters({
      snap: 'snapshot'
    }),
    data () {
      return {
        restoreModal: false,
        servers: Servers,
        gridOptions: {

        },
        selectedSnap: null,
        typeInput: '',
        activeTab: 'BasicInformation',
        username: this.capitalizeFirstLetter(this.$route.params.username)
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    watch: {
      userError: function (e) {
        this.serverError(e)
      }
    },
    mounted () {
      this.emit('getSnapshot', Number(this.$route.params.id))
    },
    notifications: {
      CreateSuccessful: {
        type: VueNotifications.types.success,
        title: 'Create successful!',
        message: 'Create was successful!'
      },
      RestoreSuccessful: {
        type: VueNotifications.types.success,
        title: 'Restore successful!',
        message: 'Restore was successful!'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      UserNotFound: {
        type: VueNotifications.types.error,
        title: 'Username not found!',
        message: 'Username doesn\'t exist.'
      },
      CreateProgress: {
        type: VueNotifications.types.info,
        title: 'Snapshot in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      RestoreProgress: {
        type: VueNotifications.types.info,
        title: 'Restore in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      SnapshotCreateFailed: {
        type: VueNotifications.types.error,
        title: 'Snapshot create failed!',
        message: 'Snapshot create failed!'
      },
      SnapshotRestoreFailed: {
        type: VueNotifications.types.error,
        title: 'Restore failed!',
        message: 'Snapshot restore failed!'
      }
    },
    methods: {
      ...mapActions(['setLock']),
      showModal (snap) {
        // this.$refs.typeUsername.focus()
        this.selectedSnap = snap
        this.typeInput = ''
        this.restoreModal = true
      },
      beforeMount () {
        this.gridOptions = Object.assign({}, DefaultGridOptions, {
          animateRows: true,
          rowSelection: 'multiple'
        })
      },
      getColumns(data) {
        let defs = []
        for (const def of Object.keys(data)) {
          defs.push({'field': def})

        }
        defs.sort()
        return defs
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      createSnapshot (e) {
        this.setLock(true)
        this.emit('createSnapshot', {username: this.$route.params.username, server: e})
        this.CreateProgress()
      },
      restoreSnapshot (id, server) {
        this.restoreModal = false
        this.setLock(true)
        this.emit('restoreSnapshot', {value: this.$route.params.username, server: server, id: id})
        this.RestoreProgress()
      },
      LoadSnaps () {
      },
      humanFileSize (bytes, si) {
        let thresh = si ? 1000 : 1024
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B'
        }
        let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let u = -1
        do {
          bytes /= thresh
          ++u
        } while (Math.abs(bytes) >= thresh && u < units.length - 1)
        return bytes.toFixed(1) + ' ' + units[u]
      },
      serverError (val) {
        switch (val.error) {
          case 3:
            this.UsernameTaken()
            break
          case 2:
            this.UserOnline()
            break
          case 10:
            this.CreateSuccessful()
            this.LoadSnaps()
            break
          case 20:
            this.RestoreSuccessful()
            break
          case 5:
            this.SnapshotCreateFailed()
            break
          case 15:
            this.SnapshotRestoreFailed()
            break
        }
        this.setLock(false)
      },
      getColour (server) {
        if (server in Servers) {
          return Servers[server].colour
        }
        return server
      },
      formatDate (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getSnapshots', to.params.username)
      next()
    }
  }
</script>
