<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="icon-cloud-download"></i>Snapshots
          <div class="float-right">
            <b-dropdown id="ddown1"  :disabled="!hasPermission(SnapshotCreate) || userLock" text="Create" size="sm" variant="success" class="float-right">
              <template slot="button-content">
                <i class="fa fa-magic"></i> Create
              </template>
              <b-dropdown-item class="text-capitalize" :key="index" v-for="(item, index) in servers" v-if="index !== activeServer && !item.readonly" @click="createSnapshot(index)">{{index}}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="card-body">
          <h5 v-if="snaps.length === 0" class="text-center text-gray"><i class="fa fa-frown-o"></i> No snapshot yet. Feel free to create one!</h5>
          <ul v-else class="list-group">
            <li v-for="snap in snaps"  class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1"><span :class="'badge-' + getColour(snap.server)" class="badge text-capitalize"> {{snap.server}}</span> {{snap.meta}} </h6>
                <small class="text-muted">{{formatDate(snap.date)}}</small>
              </div>
              <small>{{snap.checksum}} - Version {{snap.version}} - {{humanFileSize(snap.size)}}</small>

              <button type="button"  :disabled="!hasPermission(SnapshotRestore) || userLock" @click="showModal(snap)" class="float-right btn btn-warning btn-sm"><i class="fa fa-reply"></i> Restore</button>
              <router-link :to="'snapshot/' + snap.id">
                <button type="button"  :disabled="!hasPermission(SnapshotBrowse) || userLock" @click="showModal(snap)" class="float-right btn btn-info btn-sm"><i class="fa fa-folder-open"></i> Browse</button>
              </router-link>


            </li>


          </ul>
        </div>
      </div>
    </div>
    <b-modal title="Are you absolutely sure?" v-model="restoreModal">
      <p class="text-center"><mark>Unexpected bad things will happen if you don't read this!</mark></p>
      <p>This action <strong>cannot</strong> be undone. This will permanently delete everything from <strong>{{username}}</strong>'s account and recover selected data.</p>
      <p>Please type in the username you want to recover to confirm.</p>
      <input class="form-control" v-model="typeInput"  ref="typeUsername" id="name" type="text" autofocus/>
      <div slot="modal-footer" class="w-100">
        <b-btn size="large" class="btn-block" variant="danger" :disabled="typeInput.toLowerCase() !== username.toLowerCase()" @click="restoreSnapshot(selectedSnap.id, selectedSnap.server)">
          I understand the consequences, recover selected data
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import Servers from '../../../_servers'
  import VueNotifications from 'vue-notifications'

  export default {
    name: 'Snapshot',
    computed: mapGetters({
      snaps: 'snapshots',
      userError: 'userError',
      userLock: 'userLock'
    }),
    data () {
      return {
        restoreModal: false,
        servers: Servers,
        selectedSnap: null,
        typeInput: '',
        username: this.capitalizeFirstLetter(this.$route.params.username)
      }
    },
    watch: {
      userError: function (e) {
        this.serverError(e)
      }
    },
    mounted () {
      this.LoadSnaps()
    },
    notifications: {
      CreateSuccessful: {
        type: VueNotifications.types.success,
        title: 'Create successful!',
        message: 'Create was successful!'
      },
      RestoreSuccessful: {
        type: VueNotifications.types.success,
        title: 'Restore successful!',
        message: 'Restore was successful!'
      },
      UserOnline: {
        type: VueNotifications.types.error,
        title: 'Change failed!',
        message: 'Change failed, this user is currently online.'
      },
      UserNotFound: {
        type: VueNotifications.types.error,
        title: 'Username not found!',
        message: 'Username doesn\'t exist.'
      },
      CreateProgress: {
        type: VueNotifications.types.info,
        title: 'Snapshot in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      RestoreProgress: {
        type: VueNotifications.types.info,
        title: 'Restore in progress!',
        message: 'This may take several seconds, please be patient.'
      },
      SnapshotCreateFailed: {
        type: VueNotifications.types.error,
        title: 'Snapshot create failed!',
        message: 'Snapshot create failed!'
      },
      SnapshotRestoreFailed: {
        type: VueNotifications.types.error,
        title: 'Restore failed!',
        message: 'Snapshot restore failed!'
      }
    },
    methods: {
      ...mapActions(['setLock']),
      showModal (snap) {
        // this.$refs.typeUsername.focus()
        this.selectedSnap = snap
        this.typeInput = ''
        this.restoreModal = true
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      createSnapshot (e) {
        this.setLock(true)
        this.emit('createSnapshot', {username: this.$route.params.username, server: e})
        this.CreateProgress()
      },
      restoreSnapshot (id, server) {
        this.restoreModal = false
        this.setLock(true)
        this.emit('restoreSnapshot', {value: this.$route.params.username, server: server, id: id})
        this.RestoreProgress()
      },
      LoadSnaps () {
        this.emit('getSnapshots', this.$route.params.username)
      },
      humanFileSize (bytes, si) {
        let thresh = si ? 1000 : 1024
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B'
        }
        let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let u = -1
        do {
          bytes /= thresh
          ++u
        } while (Math.abs(bytes) >= thresh && u < units.length - 1)
        return bytes.toFixed(1) + ' ' + units[u]
      },
      serverError (val) {
        switch (val.error) {
          case 3:
            this.UsernameTaken()
            break
          case 2:
            this.UserOnline()
            break
          case 10:
            this.CreateSuccessful()
            this.LoadSnaps()
            break
          case 20:
            this.RestoreSuccessful()
            break
          case 5:
            this.SnapshotCreateFailed()
            break
          case 15:
            this.SnapshotRestoreFailed()
            break
        }
        this.setLock(false)
      },
      getColour (server) {
        if (server in Servers) {
          return Servers[server].colour
        }
        return server
      },
      formatDate (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.emit('getSnapshots', to.params.username)
      next()
    }
  }
</script>
