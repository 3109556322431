<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 464px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :enableFilter="true"
                 :groupHeaders="true"
                 :suppressRowClickSelection="true"
                 :getContextMenuItems="getContextMenuItems"
                 :toolPanelSuppressGroups="true"
                 :toolPanelSuppressValues="true">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    computed: mapGetters({
      sharedIPs: 'sharedIPs'
    }),
    methods: {
      createRowData () {
        return this.records
      },
      createColumnDefs () {
        let self = this
        return [
          {
            headerName: 'Type',
            field: 'type',
            filter: 'agSetColumnFilter',
            cellRenderer: function (params) {
              console.log(params)
              return '<i class="fa fa-' + self.typeIcon(params.value) + '"></i> ' + params.value
            }
          },
          {
            headerName: 'IP',
            field: 'ip',
            filter: 'agSetColumnFilter'
          },
          {
            headerName: 'Location',
            field: 'location',
            filter: 'agSetColumnFilter',
            valueGetter: function (value) {
              return value.data.location
            },
            cellRenderer: function (params) {
              return '<i class="flag-icon flag-icon-' + params.value.iso_code.toLowerCase() + '"></i> ' + params.value.location
            }
          },
          {
            headerName: 'Date',
            field: 'date',
            sort: 'desc',
            filter: 'agDateColumnFilter',
            cellFormatter: this.showDate
          }
        ]
      },
      formatMinutes (minutes) {
        let h = Math.floor(minutes / 60)
        let m = minutes % 60
        if (h <= 0) {
          return m + 'm'
        } else {
          return h + 'h'
        }
      },
      formatPrice (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      formatDate (date) {
        return moment(date).utc()
      },
      typeIcon (type) {
        switch (type) {
          case 'ServerTransfer':
            return 'exchange'
          case 'ChangePassword':
            return 'lock'
          case 'ChangeEmail':
            return 'envelope-open'
          default:
            return ''
        }
      },
      getContextMenuItems: function getContextMenuItems (params) {
        return [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: ' Check IP',
            icon: '<i class="fa fa-globe"/>',
            action: function () {
              let win = window.open('https://whatismyipaddress.com/ip/' + params.node.data.ip, '_blank')
              win.focus()
            }
          },
          'separator',
          'export'
        ]
      },
      showDate (params) {
        return moment(params.value).utc()
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple'
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
