<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="icon-cursor"></i>Write News
        </div>
        <div class="card-body">
          <div class="form-group">
            <input class="form-control" id="title" v-model="title" type="text" placeholder="Enter your title">
          </div>
          <mavon-editor language='en' v-model="content"/>
          <br>
          <button type="button" @click="createNews" class="btn btn-green float-right" :disabled="!hasPermission(NewsCreate) || this.title.length === 0 || this.content.length === 0 ">Publish</button>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
  import {mavonEditor} from 'mavon-editor'
  import 'mavon-editor/dist/css/index.css'
  import VueNotifications from 'vue-notifications'

  export default {
    name: 'CreateNews',
    components: {
      mavonEditor
    },
    notifications: {
      CreatedNews: {
        type: VueNotifications.types.success,
        title: 'News posted',
        message: 'Your news were successfully posted!'
      }
    },
    data () {
      return {
        title: '',
        content: ''
      }
    },
    methods: {
      createNews () {
        this.CreatedNews()
        this.emit('createNews', {'title': this.title, 'message': this.content})
        this.content = ''
        this.title = ''
      }
    }
  }
</script>
