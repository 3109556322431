<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 700px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :suppressClickEdit="true"
                 :enableFilter="true"
                 :getContextMenuItems="getContextMenuItems"
                 :groupHeaders="true"
                 :suppressRowClickSelection="true"
                 :toolPanelSuppressGroups="true"
                 :toolPanelSuppressValues="true">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import VueNotifications from 'vue-notifications'

  export default {
    props: ['records', 'server', 'readonly'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        suppressMovable: true
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      },
      getContextMenuItems: function getContextMenuItems (params) {
        let self = this
        return [
          'copy',
          'copyWithHeaders',
          'separator',
          {
            name: 'Delete',
            icon: '<i class="fa fa-trash"/>',
            disabled: self.readonly || !params.column || !self.hasPermission(self.GlobalVarsDelete),
            action: function () {
              self.gridOptions.api.updateRowData({remove: [params.node.data]})
              self.emit('deleteGlobalVar', {id: params.node.data.id, server: self.server})
              self.ChangeSuccessful()
            }
          },
          'separator',
          'export'
        ]
      },
      createColumnDefs () {
        return [
          {
            headerName: 'ID',
            field: 'id',
            width: 40,
            hide: false,
            filter: 'agNumberColumnFilter'
          },
          {
            headerName: 'Name',
            field: 'name',
            filter: 'agSetColumnFilter',
            sort: 'asc'
          },
          {
            headerName: 'Value',
            field: 'value'
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = {
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        enableSorting: true,
        enableFilter: true,
        enableColResize: true,
        showToolPanel: false,
        toolPanelSuppressValues: true,
        toolPanelSuppressPivotMode: true,
        toolPanelSuppressPivots: true,
        toolPanelSuppressRowGroups: true,
        menuTabs: [],
        groupDefaultExpanded: 1
      }
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    },
    notifications: {
      ChangeSuccessful: {
        type: VueNotifications.types.success,
        title: 'Change successful!',
        message: 'Change was successful!'
      }
    }
  }
</script>

<style>
</style>
