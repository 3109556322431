export default [
  {name: 'Player', colour: '#4eb8ff'},
  {name: 'Developer', colour: '#ff0000'},
  {name: 'Game Master', colour: '#0ce91b'},
  {name: 'Content Scripter', colour: '#161f1f'},
  {name: 'Community Coordinator', colour: '#09f4fd'},
  {name: 'Administrator', colour: '#6c0aeb'},
  {name: 'Artist', colour: '#f611c9'},
  {name: 'Moderator', colour: '#e67e22'},
  {name: 'Mapper', colour: '#2E43E1'},
  {name: 'Map Apprentice', colour: '#99aab5'},
  {name: 'Trade Moderator', colour: '#FF4500'},
  {name: 'Chief Executive Officer', colour: '#8934F8'},
  {name: 'Spawn Editor', colour: '#098a96'},
  {name: 'Tester', colour: '#ff6166'},
  {name: 'CS Apprentice', colour: '#99aab5'},
  {name: 'CC Apprentice', colour: '#99aab5'},
  {name: 'Artist Apprentice', colour: '#99aab5'},
  {name: 'Mod Apprentice', colour: '#99aab5'},
  {name: 'T-Mod Apprentice', colour: '#99aab5'},
  {name: 'Tester Apprentice', colour: '#99aab5'}]
