<template>
  <div>

    <ag-grid-vue style='width: 100%; height: 300px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :rowData="records">
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records'],
    data () {
      return {
        gridOptions: null
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      createColumnDefs () {
        return [
          {
            headerName: 'User',
            field: 'username',
            filter: 'agSetColumnFilter',
            pinned: 'left'
          },
          {
            headerName: 'Count',
            filter: 'agNumberColumnFilter',
            field: 'count',
            sortable: true,
            sort: 'desc'
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        rowSelection: 'multiple',
        sidebar: true
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
