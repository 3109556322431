import axios from 'axios'
export default {
  login (user, pass, cb) {
    cb = arguments[arguments.length - 1]
    let bodyFormData = new FormData()
    bodyFormData.set('username', user)
    bodyFormData.set('password', pass)
    axios({
      method: 'post',
      url: '/login',
      data: bodyFormData
    }).then(function (response) {
      if (cb) {
        if (!response.data) {
          // eslint-disable-next-line
          cb(false)
        } else {
          // eslint-disable-next-line
          cb(true)
        }
      }
    })
  }
}
