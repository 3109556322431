<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>Userlog
        </div>
        <div class="card-body">
          <Table></Table>
      </div>
    </div>
  </div>
  </div>

</template>


<script>
import Permissions from '../../_permissions'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import Servers from '../../_servers'
import LiveEdit from '../../components/LiveEdit.vue'
import Autocomplete from '../admin/Permissions/UsernameSearch.vue'
import Table from "@/views/userlog/Table.vue";

export default {
  name: 'Userlog',
  computed: mapGetters({
    auditlog: 'Auditlog',
    count: 'LogCount'
  }),
  data () {
    return {
      actions: ['ChangeRole',
        'ForceRestart',
        'GlobalVarsDelete',
        'InventoryAddItem',
        'InventoryEditItem',
        'InventoryRemoveItem',
        'MassbanIPs',
        'MassbanUsers',
        'NewsCreate',
        'NewsDelete',
        'NewsEdit',
        'PokemonAdd',
        'PokemonDelete',
        'PokemonDelevel',
        'PokemonEditAbility',
        'PokemonEditIVS',
        'PokemonEditNature',
        'PokemonEditOT',
        'PokemonEditTradelock',
        'PokemonOwnerTransfer',
        'PokemonRecover',
        'PokemonView',
        'SnapshotCreate',
        'UserBan',
        'UserCoinChange',
        'UserEmailChange',
        'UserMoneyChange',
        'UserNameChange',
        'UserRatingChange',
        'UserReset',
        'UserServerTransfer',
        'UserUnmerge',
        'VariablesDelete', 'VariablesEdit'],
      page: -1,
      filterbyUser: '',
      displayImportant: false,
      displayReason: false,
      filterbyAction: '',
      permissions: Permissions
    }
  },
  created () {
    this.clearAuditlog()
  },
  components: {
    Table,
    Autocomplete,
    LiveEdit
  },
  mounted () {
    this.emit('getAuditlog', {
      'page': 1,
      'count': false,
      'display_only_important': this.displayImportant,
      'display_only_reasons': this.displayReason,
      'filterbyAction': this.filterbyAction,
      'user': true,
      'filter_by_user': ''
    })
  },
  watch: {
    displayImportant () {
      this.updateLog()
    },
    displayReason () {
      this.updateLog()
    },
    filterbyAction () {
      this.updateLog()
    },
    filterbyUser () {
      this.updateLog()
    }
  },
  methods: {
    ...mapActions(['clearAuditlog']),
    updateLog () {
      this.clearAuditlog()
      this.page = 1
      this.emit('getAuditlog', {
        'page': this.page,
        'count': false,
        'display_only_important': this.displayImportant,
        'display_only_reasons': this.displayReason,
        'filterbyAction': this.filterbyAction,
        'user': true,
        'filter_by_user': ''
      })
    },
    fetchData (e) {
      this.clearAuditlog()
      this.emit('getAuditlog', {
        'page': e,
        'count': false,
        'display_only_important': this.displayImportant,
        'display_only_reasons': this.displayReason,
        'filterbyAction': this.filterbyAction,
        'user': true,
        'filter_by_user': ''
      })
    },
    important (perm) {
      if ('Important' in perm) {
        return 'switch-warning-outline-alt'
      }
      return 'switch-primary-outline-alt'
    },
    sortedServer (log) {
      log.sort()
      return log
    },
    getColour (server) {
      if (server in Servers) {
        return Servers[server].colour
      }
      return server
    },
    getIcon (type) {
      for (let i in this.permissions) {
        for (let i2 in this.permissions[i].Permissions) {
          if (type === i2 && 'icon' in this.permissions[i].Permissions[i2]) {
            return 'fa-' + this.permissions[i].Permissions[i2].icon
          }
        }
      }
      return 'fa-pencil'
    },
    formatDate (date) {
      return moment(date).format('MMMM Do YYYY, hh:mm:ss')
    }
  }
}
</script>
