<template>
  <div>
    <ag-grid-vue style='width: 100%; height: 600px;' class='ag-theme-balham-dark'
                 :gridOptions='gridOptions'
                 :enableColResize="true"
                 :showToolPanel="showToolPanel"
                 :rowData="records"
                 :enableSorting="true"
                 :suppressClickEdit="true"
                 :enableFilter="true"
                 :getContextMenuItems="getContextMenuItems"
                 :groupHeaders="true"
                 :suppressRowClickSelection="true"
>
    </ag-grid-vue>
  </div>
</template>
<script>
  import {AgGridVue} from 'ag-grid-vue'
  import {DefaultGridOptions} from "@/default_table_settings";

  export default {
    props: ['records', 'server'],
    data () {
      return {
        gridOptions: null,
        showToolPanel: false,
        suppressMovable: true
      }
    },
    components: {
      'ag-grid-vue': AgGridVue
    },
    methods: {
      createRowData () {
        return this.records
      },
      onGridSizeChanged () {
        this.gridOptions.api.sizeColumnsToFit()
      },
      createColumnDefs () {
        return [
          {
            headerName: 'Pokemon',
            field: 'pokemon',
            filter: 'agSetColumnFilter'
          },
          {
            headerName: 'Region',
            field: 'region',
            rowGroup: true,
            filter: 'agSetColumnFilter'
          },
          {
            headerName: 'Seen',
            field: 'seen',
            aggFunc: 'sum',
            enableValue: true,
            allowedAggFuncs: ['sum'],
            filter: 'agNumberColumnFilter'
          },
          {
            headerName: 'Caught',
            field: 'caught',
            aggFunc: 'sum',
            enableValue: true,
            allowedAggFuncs: ['sum'],
            filter: 'agNumberColumnFilter'
          },
          {
            headerName: 'Evolved',
            field: 'evolved',
            aggFunc: 'sum',
            enableValue: true,
            allowedAggFuncs: ['sum'],
            filter: 'agNumberColumnFilter'
          }
        ]
      }
    },
    beforeMount () {
      this.gridOptions = Object.assign({}, DefaultGridOptions, {
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        autoGroupColumnDef: {headerName: 'MonsterID',
          field: 'id',
          filter: 'agNumberColumnFilter',
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            padding: 5
          }}
      })
      this.gridOptions.rowData = this.createRowData()
      this.gridOptions.columnDefs = this.createColumnDefs()
    },
    mounted () {
      this.gridOptions.api.sizeColumnsToFit()
      this.gridOptions.onRowDataChanged = this.onGridSizeChanged
      this.gridOptions.onGridSizeChanged = this.onGridSizeChanged
    }
  }
</script>

<style>
</style>
