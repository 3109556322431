<template>
      <b-nav-item-dropdown right no-caret>
        <template slot="button-content">

          <img :src="'https://pokemonrevolution.net/forum/avatar.php?user=' + authUser.user" alt="" class="img-avatar"/>
          {{authUser.user}} | <a class="role" :style="{color: role.colour}">{{role.name}}</a></template>
        <b-dropdown-item @click="logout"><i class="fa fa-lock"></i> Logout</b-dropdown-item>
      </b-nav-item-dropdown>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import Roles from '../../_roles'
  export default {
    name: 'header-dropdown',
    computed: {
      ...mapGetters([
        'authUser'
      ]),
      role () {
        return Roles[this.authUser.security]
      }
    },
    methods: {
      ...mapActions([
        'resetPermission'
      ]),
      logout () {
        this.resetPermission(false)
        this.emit('logout', 0)
        this.$router.push('/auth/login')
      }
    }
  }
</script>

