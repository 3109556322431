<template>
  <div id="app">
    <div v-if="authUser == null">
      <div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
          <fingerprint-spinner
            :animation-duration="1500"
            :size="128"
            color="#ff1d5e"
          />
    </div>
    </div>
    <router-view  v-else />
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import { FingerprintSpinner } from 'epic-spinners'
export default {
  name: 'app',
  computed: {
    ...mapGetters(['isConnected', 'authUser'])
  },
  components: {
    FingerprintSpinner
  }
}
</script>

<style>
  /* Import Font Awesome Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/css/font-awesome.min.css';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/css/simple-line-icons.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
<style lang="scss">
  // Import Main styles for this application
  @import './scss/style';
</style>

