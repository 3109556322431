import { render, staticRenderFns } from "./Servermenu.vue?vue&type=template&id=ae9e3700&scoped=true"
import script from "./Servermenu.vue?vue&type=script&lang=js"
export * from "./Servermenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae9e3700",
  null
  
)

export default component.exports