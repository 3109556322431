<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">Coins</span>
    </div>
    <input class="form-control" v-model="coins" :disabled="userLock" :class="error ? 'is-invalid' : ''" :readonly="allowed" type="number">
    <span class="input-group-append"><button class="btn btn-primary" :disabled="servers[server].readonly || userLock|| error != null || allowed" @click="changeCoins" type="button">Change</button></span>
    <div class="invalid-feedback">{{error}}</div>

  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import Servers from '../../../_servers'

  export default {
    name: 'Coins',
    props: ['coins', 'server', 'userid', 'allowed'],
    data () {
      return {
        error: null,
        servers: Servers
      }
    },
    computed: {
      ...mapGetters([
        'userLock'
      ])
    },
    methods: {
      validateEmail (email) {
        let re = /\S+@\S+\.\S+/
        return re.test(String(email).toLowerCase())
      },
      changeCoins () {
        this.emit('changeCoins', {
          id: this.userid,
          value: Number(this.coins),
          server: this.server
        })
        this.setLock(true)
      },
      ...mapActions(['setLock'])
    }
  }
</script>
