import Vue from 'vue'

export default Vue.extend({
  template: `
    <div class='ml-2'>
      <br>
      <b-row>
        <b-col sm='12' md='6' :key="user.user_id" v-for='(user) in firstRecord.users'>
          <h6>{{user.username}}</h6>
          <span v-if='user.money > 0'>
            <strong class='font-size-2'>{{formatMoney(user.money)}}</strong><br><br>
          </span>
          <template v-if='user.pokemon'>
          <span class="mb-4" v-for='poke in user.pokemon'>
            <template v-if="poke.Special == 1">
              Shiny
            </template> {{poke.Pokemon}} Lv. {{poke.LVL}}
            <template v-if="poke.Item">
              ({{poke.Item}}),
            </template>
            <template v-if="poke.Form && poke.Form > 0">
              Form: {{poke.Form}}
            </template>
            <template v-if="poke.Nature">
              {{poke.Nature}},
            </template>
            <template v-if="poke.Ability">
              {{poke.Ability}}
            </template>
            <template v-if="poke.ID">
              UID: {{poke.ID}}
            </template>
            <template v-if="poke.GID">
              GID: {{poke.GID}}<br>
            </template>
            <template v-if="poke.Username">
          Current Owner: <span :class="'text-' + poke.server">{{poke.Username}}</span>,
            </template>
            <template v-if="poke.IV_ATK">
              IVs: {{poke.IV_ATK}}, {{poke.IV_DEF}}, {{poke.IV_SPD}}, {{poke.IV_SPATK}}, {{poke.IV_SPDEF}}, {{poke.IV_HP}}
            </template>
            <template v-if="poke.EV_ATK != null">
              EVs: {{poke.EV_ATK}}, {{poke.EV_DEF}}, {{poke.EV_SPD}}, {{poke.EV_SPATK}}, {{poke.EV_SPDEF}}, {{poke.EV_HP}}
            </template>
            <template v-if="poke.ID && !poke.Username">
              (ID NOT FOUND)
            </template><br>
          </span><br>
          </template>
          <template v-if='user.items'>
            <span v-for='item in user.items'>
              {{item.qty}}x {{item.item }} <br>
            </span>
            </template>
        </b-col>
      </b-row>
    </div>


    `,
  data: function () {
    return {
      firstRecord: {}
    }
  },
  beforeMount () {
  },
  mounted () {
    this.firstRecord = this.params.data
  },
  methods: {
    // called when the cell is refreshed
    refresh () {
      return false
    },
    formatMoney (money) {
      return money.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0})
    }
  }
})
