import Vue from 'vue'
import Vuex from 'vuex'
import pokemons from './modules/pokemons'
import dashboard from './modules/dashboard'
import userData from './modules/userdata'
import permissions from './modules/permissions'
import autocomplete from './modules/autocomplete'
import Inventory from './modules/inventory'
import Variables from './modules/variables'
import Tradelog from './modules/tradelog'
import PVPlog from './modules/pvplog'
import Auditlog from './modules/admin'
import Connections from './modules/connections'
import MassBan from './modules/massban'
import OnlineList from './modules/onlineList'
import Pokedex from './modules/pokedex'
import Requests from './modules/requests'
import News from './modules/news'
import GlobalVars from './modules/globalvars'
import UserHistory from './modules/userhistory'
import Chatlog from './modules/chatlog'
import Stats from './modules/stats'
import GuildHistory from './modules/guildhistory'
import Watchlist from './modules/watchlist'
import VueNotifications from "vue-notifications";
import iziToast from "izitoast";
import Cookies from 'js-cookie';
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    pokemons,
    dashboard,
    userData,
    UserHistory,
    permissions,
    autocomplete,
    Inventory,
    Variables,
    Tradelog,
    Stats,
    Auditlog,
    Connections,
    MassBan,
    OnlineList,
    Pokedex,
    Requests,
    GlobalVars,
    News,
    GuildHistory,
    Chatlog,
    Watchlist,
    PVPlog
  },
  getters: {
    authUser: state => state.socket.user,
    isConnected: state => state.socket.isConnected,
    reconnecting: state => state.socket.reconnecting
  },
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnecting: 0,
      reconnectError: false,
      user: null
    }
  },
  mutations: {
    SOCKET_ONOPEN (state, event) {
      state.socket.isConnected = true
      state.socket.reconnecting = 0
    },
    SOCKET_ONCLOSE (state, event) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message) {
      state.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (state, count) {
      state.socket.reconnecting = count
      state.socket.reconnectError = false
    },
    setAuth (state, auth) {
      state.socket.user = auth
    }
  },
  actions: {
    permission ({ commit }, message) {
      const permissionData = message.data;
      commit('setAuth', permissionData)
      if (!permissionData) {
        // Redirect to login or unauthorized page
        router.push({ name: 'Login' }); // or { path: '/login' } depending on your route setup
      }
    },
    sendSystemMessage({commit}, message) {
      iziToast[message.data.type]({
        title:  message.data.title,
        message: message.data.message,
      });
      },

    restartTools({commit}, message) {
      window.location.reload();
    },
    resetPermission ({ commit }, message) {
      commit('setAuth', message)
    }

  }
})
