<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-dollar"></i></span>
    </div>
    <money class="form-control" v-model="price" v-bind="money"></money>

    <span class="input-group-append"><button class="btn btn-primary" :disabled="servers[server].readonly || userLock || error != null || allowed" @click="changeMoney" type="button">Change</button></span>
    <div class="invalid-feedback">{{error}}</div>

  </div>
</template>
<script>
  import {Money} from 'v-money'
  import { mapGetters, mapActions } from 'vuex'
  import Servers from '../../../_servers'

  export default {
    name: 'username',
    props: ['price', 'userid', 'allowed', 'server'],
    components: {Money},
    data () {
      return {
        error: null,
        servers: Servers,
        money: {
          decimal: ',',
          thousands: '.',
          precision: 0,
          masked: false
        }
      }
    },
    computed: {
      ...mapGetters([
        'userLock'
      ])
    },
    methods: {
      validUsername () {
        if (this.user.length < 4) {
          this.error = 'This username is too short.'
        } else if (this.user.length > 15) {
          this.error = 'This username is too long.'
        } else if (this.user.length > 15) {
          this.error = 'This username is too long.'
        } else if (!this.isAlphaNumeric(this.user)) {
          this.error = 'The use of non-alphanumeric characters is allowed.'
        } else {
          this.error = null
        }
      },
      changeMoney () {
        this.setLock(true)
        this.emit('changeMoney', {
          id: this.userid,
          value: this.price,
          server: this.server
        })
      },
      ...mapActions(['setLock'])
    }
  }
</script>
